import { FormikErrors } from 'formik';

import {
  isAvailableZipCode,
  isValidEmail,
  isValidPhoneNumber,
  isValidZipCode,
} from '../../../utils/validation.utils';
import { ReferralState } from '../ReferrerForm/ReferrerForm';
import { ReferralFormValues } from './ReferralForm';

const ReferralFormValidator = async (
  values: ReferralFormValues,
  index: number,
): Promise<FormikErrors<{ referrals: ReferralState[] }>> => {
  const errors: FormikErrors<{ referrals: ReferralState[] }> = {};

  errors.referrals = values.referrals.map((referral, index) => {
    return {
      first_name: '',
      last_name: '',
      phone: '',
      email_addr_addr: '',
      relationship: '',
      zipcode: '',
    };
  });

  const { first_name, last_name, zipcode, email_addr, phone, relationship } =
    values.referrals[index];

  if (!first_name) {
    errors.referrals[index].first_name = 'Required';
  } else if (first_name.length > 64) {
    errors.referrals[index].first_name =
      'Please enter no more than 64 characters';
  }

  if (!last_name) {
    errors.referrals[index].last_name = 'Required';
  } else if (last_name.length > 64) {
    errors.referrals[index].last_name =
      'Please enter no more than 64 characters';
  }

  if (email_addr && !isValidEmail(email_addr)) {
    errors.referrals[index].email_addr =
      'Please provide a valid email_addr address';
  } else if (email_addr.length > 64) {
    errors.referrals[index].email_addr =
      'Please enter no more than 64 characters';
  }

  if (!phone) {
    errors.referrals[index].phone = 'Required';
  } else if (!isValidPhoneNumber(phone)) {
    errors.referrals[index].phone = 'Please provide a valid phone number';
  }

  if (!zipcode) {
    errors.referrals[index].zipcode = 'Required';
  } else if (!isValidZipCode(zipcode)) {
    errors.referrals[index].zipcode = 'Please provide a valid 5-digit zip code';
  } else if ((await isAvailableZipCode(zipcode)) === false) {
    errors.referrals[index].zipcode =
      'Life insurance and annuity products are currently not available in your state. If you are a current member in need of assistance, please contact our Customer Service team at 833-WOODMEN (833-966-3636).';
  }

  let hasErrors = false;

  Object.entries(errors.referrals[index]).forEach(([key, value]) => {
    if (key !== 'relationship' && value !== '') {
      hasErrors = true;
    }
  });

  return hasErrors ? errors : {};
};

export default ReferralFormValidator;
