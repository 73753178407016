import { FormikErrors } from 'formik';

import {
  isAvailableZipCode,
  isValidEmail,
  isValidPhoneNumber,
  isValidZipCode,
} from '../../../utils/validation.utils';
import { ReferrerFormValues } from '../ReferrerForm/ReferrerForm';

const ReferrerFormValidator = async (
  values: ReferrerFormValues,
): Promise<FormikErrors<ReferrerFormValues>> => {
  let errors: FormikErrors<ReferrerFormValues> = {};

  let referralsArray = [];

  values.referrals.forEach((referral) => {
    if (referral.editState === 'created' || referral.editState === 'updated') {
      referralsArray.push(referral);
    }
  });

  if (!referralsArray.length) {
    errors.referrals = 'Required';
  }

  if (!values.first_name) {
    errors.first_name = 'Required';
  } else if (values.first_name.length > 64) {
    errors.first_name = 'Please enter no more than 50 characters';
  } else {
  }

  if (!values.last_name) {
    errors.last_name = 'Required';
  } else if (values.last_name.length > 64) {
    errors.last_name = 'Please enter no more than 50 characters';
  }

  if (!values.addressLine1) {
    errors.addressLine1 = 'Required';
  } else if (values.addressLine1.length > 128) {
    errors.addressLine1 = 'Please enter no more than 50 characters';
  }

  if (values.addressLine2.length > 128) {
    errors.addressLine2 = 'Please enter no more than 155 characters';
  }

  if (!values.city) {
    errors.city = 'Required';
  } else if (values.city.length > 64) {
    errors.city = 'Please enter no more than 50 characters';
  }

  if (!values.state) {
    errors.state = 'Required';
  }

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!isValidPhoneNumber(values.phone)) {
    errors.phone = 'Please provide a valid phone number';
  }

  if (values.email_addr && !isValidEmail(values.email_addr)) {
    errors.email_addr = 'Please provide a valid email address';
  } else if (values.email_addr.length > 64) {
    errors.email_addr = 'Please enter no more than 150 characters';
  }

  if (!values.zipcode) {
    errors.zipcode = 'Required';
  } else if (!isValidZipCode(values.zipcode)) {
    errors.zipcode = 'Please provide a valid 5-digit zip code';
  } else if ((await isAvailableZipCode(values.zipcode)) === false) {
    errors.zipcode =
      'Life insurance and annuity products are currently not available in your state. If you are a current member in need of assistance, please contact our Customer Service team at 833-WOODMEN (833-966-3636).';
  }

  return errors;
};

export default ReferrerFormValidator;
