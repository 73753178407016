import { VStack } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Form, FormikProps, FormikValues, withFormik } from 'formik';
import React from 'react';

import { WLButton, WLH2, WLInput } from '../../../design-library';
import { PhoneInputField } from '../../Form';
import FormikFieldAdapter from '../../Form/FormikFieldAdapter';
import { ReferralState } from '../ReferrerForm/ReferrerForm';
import ReferralFormValidator from './ReferralFormValidator';

export type EditType = 'created' | 'updated' | 'deleted' | 'new';

export interface ReferralFormProps {
  referrals: ReferralState[];
  editState: EditType | null | undefined;
  handleReferrals: (
    action: EditType,
    index: number,
    payload?: ReferralState,
  ) => void;
  activeReferralIndex: number;
  open: boolean;
  onClose: () => void;
}

const ReferralForm: React.FC<ReferralFormProps & FormikProps<FormikValues>> = ({
  values,
  open,
  onClose,
  handleReferrals,
  setFieldValue,
  activeReferralIndex,
  referrals,
  editState,
  isValid,
  dirty,
}) => {
  return (
    <Modal isCentered isOpen={open} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton
          onClick={() =>
            setFieldValue(
              `referrals[${activeReferralIndex}]`,
              referrals[activeReferralIndex],
            )
          }
        />
        <ModalBody>
          <Form style={{ width: '100%' }}>
            <VStack width="full" spacing={6}>
              <WLH2 color="wl-blue.navy" width="full">
                Referral Info
              </WLH2>
              <FormikFieldAdapter
                name={`referrals[${activeReferralIndex}].first_name`}
                label={'First Name'}
                required
                renderField={(adapterProps) => (
                  <WLInput {...adapterProps} variant="flushed" maxLength={64} />
                )}
              />
              <FormikFieldAdapter
                name={`referrals[${activeReferralIndex}].last_name`}
                label={'Last Name'}
                required
                renderField={(adapterProps) => (
                  <WLInput {...adapterProps} variant="flushed" maxLength={64} />
                )}
              />

              <FormikFieldAdapter
                name={`referrals[${activeReferralIndex}].phone`}
                label="Phone"
                helperText="###-###-####"
                required={true}
                renderField={(adapterProps) => (
                  <PhoneInputField
                    {...adapterProps}
                    required
                    variant="flushed"
                    maxLength={14}
                  />
                )}
              />
              <FormikFieldAdapter
                name={`referrals[${activeReferralIndex}].email_addr`}
                label="Email"
                helperText="Optional"
                renderField={(adapterProps) => (
                  <WLInput {...adapterProps} variant="flushed" maxLength={64} />
                )}
              />
              <FormikFieldAdapter
                name={`referrals[${activeReferralIndex}].zipcode`}
                label={'ZIP Code'}
                required
                renderField={(adapterProps) => (
                  <WLInput {...adapterProps} variant="flushed" maxLength={5} />
                )}
              />
              <FormikFieldAdapter
                name={`referrals[${activeReferralIndex}].relationship`}
                label={'Relationship'}
                helperText="Optional"
                renderField={(adapterProps) => (
                  <WLInput {...adapterProps} variant="flushed" maxLength={64} />
                )}
              />
              <VStack spacing={2}>
                {(editState === 'new' || !editState) && (
                  <WLButton
                    colorScheme={'wl-blue'}
                    onClick={() => {
                      onClose();
                      handleReferrals(
                        'created',
                        activeReferralIndex,
                        values.referrals[activeReferralIndex],
                      );
                    }}
                    isDisabled={!(isValid && dirty)}
                  >
                    {`Add`}
                  </WLButton>
                )}
                {editState === 'created' && (
                  <WLButton
                    colorScheme={'wl-blue'}
                    onClick={() => {
                      onClose();
                      handleReferrals(
                        'updated',
                        activeReferralIndex,
                        values.referrals[activeReferralIndex],
                      );
                    }}
                    isDisabled={!isValid}
                  >{`Update`}</WLButton>
                )}
                {(editState === 'updated' || editState === 'created') && (
                  <WLButton
                    colorScheme={'wl-blue'}
                    onClick={() => {
                      onClose();
                      handleReferrals('deleted', activeReferralIndex);
                    }}
                  >
                    {`Delete`}
                  </WLButton>
                )}

                <WLButton
                  colorScheme={'red'}
                  onClick={() => {
                    setFieldValue(
                      `referrals[${activeReferralIndex}]`,
                      referrals[activeReferralIndex],
                    );
                    onClose();
                  }}
                >
                  {`Cancel`}
                </WLButton>
              </VStack>
            </VStack>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export interface ReferralFormValues {
  referrals: {
    first_name: string;
    last_name: string;
    phone: string;
    email_addr: string;
    zipcode: string;
    relationship?: string;
    _utmParams: {};
  }[];
}
const EnhancedReferreralForm = withFormik<
  ReferralFormProps,
  ReferralFormValues
>({
  mapPropsToValues: (props) => ({
    referrals: props.referrals.map((referral, index) => {
      return {
        [`first_name`]: referral.first_name,
        [`last_name`]: referral.last_name,
        [`phone`]: referral.phone,
        [`email_addr`]: referral.email_addr,
        [`zipcode`]: referral.zipcode,
        [`relationship`]: referral.relationship,
      };
    }),
    activeReferralIndex: props.activeReferralIndex,
  }),
  mapPropsToErrors: (props) => ({
    referrals: props.referrals.map((referral, index) => {
      return {
        [`first_name`]: '',
        [`last_name`]: '',
        [`phone`]: '',
        [`email_addr`]: '',
        [`zipcode`]: '',
        [`relationship`]: '',
      };
    }),
  }),
  validate: (values, props) =>
    ReferralFormValidator(values, props.activeReferralIndex),
  enableReinitialize: true,
  validateOnMount: true,
  handleSubmit: () => {},
})(ReferralForm);

export default EnhancedReferreralForm;
