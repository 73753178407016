import { Box, Flex, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import React from 'react';

import { BasicAssetFragment, ShareYourCareQuery } from '../../../graphql-types';
import { WLContainer, WLH2 } from '../../design-library';
import RichText, { RichTextAlignment } from '../RichText';
import WoodmenLogo from '../ShareYourCare/svg/woodmen-logo_white.inline.svg';
import PhotoCollageAdapter from './PhotoCollageAdapter/PhotoCollageAdapter';
import EnhancedReferrerForm from './ReferrerForm/ReferrerForm';

interface ShareYourCareProps {
  data: ShareYourCareQuery;
  location: PageProps;
}

const ShareYourCare: React.FC<ShareYourCareProps> = ({ data, location }) => {
  if (!data.contentfulTemplateShareYourCare) {
    return null;
  }

  const { contentfulTemplateShareYourCare: template } = data;
  return (
    <>
      {/* App Header */}
      <Box
        width="full"
        bg="wl-blue.navy"
        marginBottom={20}
        _after={{
          base: {
            content: `" "`,
            width: 'full',
            height: '50px',
            position: 'absolute',

            marginBottom: '-40px',
            backgroundColor: 'wl-blue.navy',
            borderRadius: '50% 50% 50% 50%/0 0 50% 50%',
          },
          tablet: { display: 'none' },
        }}
      >
        <Flex
          height={{ base: 'auto', tablet: '220px' }}
          justify={{ base: 'center', tablet: 'space-between' }}
          align={{ base: 'center', tablet: 'unset' }}
          maxW="1024px"
          width="full"
          margin={'0 auto'}
          paddingY={'24px'}
          direction={{ base: 'column-reverse', tablet: 'row' }}
        >
          <img src="/images/syc-logo.png" alt="Share Your Care" />
          <WoodmenLogo style={{ width: '240px', height: '80px' }} />
        </Flex>
      </Box>

      {/* content */}
      <WLContainer width="WIDE" padding={8}>
        <SimpleGrid
          templateColumns={{
            base: '1fr',
            lg:
              data.contentfulTemplateShareYourCare.status === 'open'
                ? '6fr 4fr'
                : '1fr',
          }}
          spacing={[4, null]}
        >
          <VStack
            spacing={4}
            justifySelf="center"
            paddingX={[2, null, 4]}
            width="full"
            textAlign={['center', null, 'left']}
            maxWidth={[526, null, 'none']}
          >
            {data.contentfulTemplateShareYourCare.status === 'open' && (
              <>
                <WLH2 color="wl-blue.navy" width="full" fontFamily={'Inter'}>
                  Refer Your Loved Ones and Have the Chance to Win $1,000
                </WLH2>
                <HStack
                  width="full"
                  minWidth={280}
                  spacing={4}
                  justify={'center'}
                  align={'center'}
                >
                  {template?.collageImages && (
                    <PhotoCollageAdapter
                      data={template?.collageImages as BasicAssetFragment[]}
                    />
                  )}
                </HStack>
              </>
            )}
            {template?.bodyCopy && (
              <RichText
                alignment={
                  data.contentfulTemplateShareYourCare.status === 'closed'
                    ? RichTextAlignment.LEFT
                    : undefined
                }
                value={template.bodyCopy.content as RenderRichTextData<never>}
              />
            )}
          </VStack>

          {data.contentfulTemplateShareYourCare.status === 'open' && (
            <VStack
              spacing={4}
              justifySelf="center"
              paddingX={[2, null, 4]}
              width="full"
              maxWidth={526}
            >
              <EnhancedReferrerForm data={data} location={location} />
            </VStack>
          )}
        </SimpleGrid>
      </WLContainer>
    </>
  );
};

export default ShareYourCare;
