import { Box, HStack, VStack } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import ImageAdapter from '../../ImageAdapter';

interface PhotoCollageProps {
  children: React.ReactNode[];
}

const PhotoCollage: React.FC<PhotoCollageProps> = ({ children }) => {
  if (!children) {
    return null;
  }

  const childArray = React.Children.toArray(children);
  return (
    <HStack>
      {React.Children.toArray(children).map(
        (child: React.ReactNode, index: number) =>
          index % 2 === 1 ? (
            <VStack>
              <Box>{children[index - 1]} </Box>
              <Box>{child} </Box>
            </VStack>
          ) : (
            children.length - 1 === index && <VStack>{child}</VStack>
          ),
      )}
    </HStack>
  );
};
export default PhotoCollage;
