import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { ShareYourCareQuery } from '../../../../graphql-types';
import SEO from '../../../components/SEO';
import ShareYourCare from '../../../components/ShareYourCare/ShareYourCare';
import { WLContainer } from '../../../design-library';

interface ShareYourCareProps {
  data: ShareYourCareQuery;
  location: PageProps;
}

const ShareYourCarePage: React.FC<ShareYourCareProps> = ({
  data,
  location,
}) => {
  if (!data.contentfulTemplateShareYourCare) {
    return null;
  }

  return (
    <>
      <SEO
        title="Share Your Care"
        description="Recommend those who you feel could benefit from WoodmenLife life insurance, retirement products, member benefits, and commitment to giving back."
        meta={[{ name: 'robots', content: 'noindex' }]}
      />
      <WLContainer width="FULL_WIDTH">
        <ShareYourCare data={data} location={location} />
      </WLContainer>
    </>
  );
};

export const pageQuery = graphql`
  query ShareYourCare {
    contentfulTemplateShareYourCare {
      bodyCopy {
        id
        name
        content {
          raw
        }
      }
      status
      rules {
        ...AboutSection
      }
      collageImages {
        description
        gatsbyImageData
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      logo {
        id
        description
        gatsbyImageData
        file {
          url
        }
      }
    }
  }
`;

export default ShareYourCarePage;
