import { LeadPostRequest, LeadReferrerPostRequest } from '../../apis';
import { leadSubmitMessage, referrerSubmitMessage } from './types';

export const mapLeadToRequest = (
  payload: leadSubmitMessage,
): LeadPostRequest => {
  return {
    leadRequest: {
      firstName: payload.first_name,
      lastName: payload.last_name,
      emailAddress: payload.email_addr || undefined,
      phone: payload.phone,
      zipCode: payload.zipcode,
      refPage: 'Share Your Care',
      referrerId: payload.referrerId,
      referralRelationship: payload.relationship || null,
      skipSubscribe: true,
      utmParameters: payload._utmParams,
    },
  };
};

export const mapReferrerToRequest = (
  payload: referrerSubmitMessage,
): LeadReferrerPostRequest => {
  return {
    leadReferrerRequest: {
      firstName: payload.first_name,
      lastName: payload.last_name,
      addressLine1: payload.addressLine1,
      addressLine2: payload.addressLine2,
      city: payload.city,
      state: payload.state,
      zipCode: payload.zipcode,
      phone: payload.phone,
      emailAddress: payload.email_addr,
      comments: payload.comments,
      skipSubscribe: true,
    },
  };
};
