import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Form, FormikProps, withFormik } from 'formik';
import { PageProps } from 'gatsby';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import React, { useRef } from 'react';

import { ShareYourCareQuery } from '../../../../graphql-types';
import {
  ApiConfiguration,
  LeadApi,
  LeadPostRequest,
  LeadReferrerPostRequest,
  LeadResponse,
} from '../../../apis';
import { StatesByAbbreviation } from '../../../constants/states';
import { WLH3 } from '../../../design-library/';
import {
  WLButton,
  WLFormStatusFeedback,
  WLH2,
  WLInput,
  WLLink,
  WLSelect,
  WLText,
  WLTextarea,
} from '../../../design-library/';
import { SelectVariant } from '../../../design-library/Forms/Select/Select';
import { isZipValidationError } from '../../../utils/validation.utils';
import { PhoneInputField } from '../../Form';
import FormikFieldAdapter from '../../Form/FormikFieldAdapter';
import RichText from '../../RichText';
import ReferralButton from '../ReferralButton/ReferralButton';
import { EditType } from '../ReferralForm/ReferralForm';
import EnhancedReferralForm from '../ReferralForm/ReferralForm';
import { leadSubmitMessage, referrerSubmitMessage } from '../types';
import { mapLeadToRequest, mapReferrerToRequest } from '../utils';
import ReferrerFormValidator from './ReferrerFormValidator';

interface ReferrerFormProps {
  location: PageProps;
  data: ShareYourCareQuery;
}

export interface ReferralState {
  editState?: EditType;
  first_name: string;
  last_name: string;
  phone: string;
  email_addr: string;
  zipcode: string;
  relationship?: string;
}

const initialReferralState = [
  {
    id: 0,
    editState: 'new' as EditType,
    first_name: '',
    last_name: '',
    phone: '',
    email_addr: '',
    zipcode: '',
    relationship: '',
  },
  {
    id: 1,
    editState: 'new' as EditType,
    first_name: '',
    last_name: '',
    phone: '',
    email_addr: '',
    zipcode: '',
    relationship: '',
  },
  {
    id: 2,
    editState: 'new' as EditType,
    first_name: '',
    last_name: '',
    phone: '',
    email_addr: '',
    zipcode: '',
    relationship: '',
  },
  {
    id: 3,
    editState: 'new' as EditType,
    first_name: '',
    last_name: '',
    phone: '',
    email_addr: '',
    zipcode: '',
    relationship: '',
  },
  {
    id: 4,
    editState: 'new' as EditType,
    first_name: '',
    last_name: '',
    phone: '',
    email_addr: '',
    zipcode: '',
    relationship: '',
  },
];

const ReferrerForm: React.FC<
  ReferrerFormProps & FormikProps<ReferrerFormValues>
> = ({
  values,
  setFieldValue,
  initialValues,
  handleSubmit,
  isValid,
  dirty,
  errors,
  isSubmitting,
  status,
  location,
  resetForm,
  data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mixpanel = useMixpanel();
  const [open, setOpen] = React.useState(false);
  const ref = useRef(
    typeof document !== `undefined` ? document.createElement('div') : null,
  );
  const [params, setParams] = React.useState<{ [key: string]: string }>();

  React.useEffect(() => {
    const newURL = new URL(encodeURI(window.location.href).replace(`/#/`, `/`));

    const urlParams = new URLSearchParams(newURL?.search);

    const entries = urlParams.entries();

    let newParams: { [key: string]: string } = {};

    for (const entry of entries) {
      if (entry[0].substring(0, 4).toLowerCase() === 'utm_') {
        newParams[entry[0]] = entry[1];
      }
    }
    setParams(newParams);
    setFieldValue('_utmParameters', newParams);
  }, []);
  const rulesContent = data.contentfulTemplateShareYourCare?.rules && (
    <RichText
      value={
        data.contentfulTemplateShareYourCare?.rules
          .content as RenderRichTextData<never>
      }
    />
  );

  const handleReferrals = (
    action: EditType,
    index: number,
    payload?: ReferralState | null | undefined,
  ): void => {
    switch (action) {
      case 'deleted':
        setFieldValue(`referrals[${index}]`, {
          ...initialValues.referrals[index],
          editState: 'new',
        });
        break;
      case 'created':
      case 'updated':
        setFieldValue(`referrals[${index}]`, { ...payload, editState: action });
        break;
      default:
        break;
    }
  };

  const stateOptions = React.useMemo(
    () =>
      Object.entries(StatesByAbbreviation).map(([abbrev, name], idx) => ({
        label: `${name} (${abbrev})`,
        value: abbrev,
      })),
    [],
  );

  React.useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    if (status === 'success') {
      values.referrals.map(
        (referral) =>
          referral.editState === 'created' &&
          window.dataLayer.push({
            event: 'generate_lead_sales',
            status: 'success',
            formType: 'salesLeadGenForm',
            formLocation: 'Share Your Care',
          }),
      );

      mixpanel.track(`Lead Form - Submit`, {
        Clicked: true,
      });
    }
    // To be implemented at a later date
    // if (status === 'error') {
    //   window.dataLayer.push({
    //     event: 'generate_lead_sales',
    //     status: 'failure',
    //     formType: 'salesLeadGenForm',
    //     formLocation: 'Share Your Care',
    //   });
    // }
    return () => {};
  }, [status, mixpanel]);

  if (status === 'success') {
    return (
      <Box ref={ref} scrollMarginTop={'10px'}>
        <WLFormStatusFeedback type="success">
          <WLH3>Thank You for participating in Share Your Care.</WLH3>
          <WLText
            textDecoration={'underline'}
            color="blue"
            _hover={{ cursor: 'pointer', textDecorationThickness: '0.15rem' }}
            _active={{ color: 'purple' }}
            onClick={() => resetForm()}
          >
            Start a new form
          </WLText>
        </WLFormStatusFeedback>
      </Box>
    );
  } else if (status === 'error') {
    return (
      <Box ref={ref} scrollMarginTop={'10px'}>
        <WLFormStatusFeedback type="success">
          <WLH3>Sorry, and error occured. Please try again.</WLH3>
          <WLText
            textDecoration={'underline'}
            color="blue"
            _hover={{ cursor: 'pointer', textDecorationThickness: '0.15rem' }}
            _active={{ color: 'purple' }}
            onClick={() => resetForm()}
          >
            Start a new form
          </WLText>
        </WLFormStatusFeedback>
      </Box>
    );
  }

  return (
    <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <VStack
        width="full"
        spacing={6}
        minWidth={{ base: 'none', tablet: '400px' }}
      >
        <WLH2 color="wl-blue.navy" width="full">
          My Info
        </WLH2>
        <FormikFieldAdapter
          name="first_name"
          label={'My First Name'}
          required
          renderField={(adapterProps) => (
            <WLInput {...adapterProps} variant="flushed" maxLength={64} />
          )}
        />
        <FormikFieldAdapter
          name="last_name"
          label={'My Last Name'}
          required
          renderField={(adapterProps) => (
            <WLInput {...adapterProps} variant="flushed" maxLength={64} />
          )}
        />
        <FormikFieldAdapter
          name="addressLine1"
          label={'My Address 1'}
          required
          renderField={(adapterProps) => (
            <WLInput {...adapterProps} variant="flushed" maxLength={128} />
          )}
        />
        <FormikFieldAdapter
          name="addressLine2"
          label={'My Address 2'}
          renderField={(adapterProps) => (
            <WLInput {...adapterProps} variant="flushed" maxLength={128} />
          )}
        />
        <FormikFieldAdapter
          name="city"
          label={'My City'}
          required
          renderField={(adapterProps) => (
            <WLInput {...adapterProps} variant="flushed" maxLength={64} />
          )}
        />
        <FormikFieldAdapter
          name="state"
          label={'My State'}
          required
          renderField={(adapterProps) => (
            <WLSelect
              {...adapterProps}
              options={stateOptions}
              variant={SelectVariant.FLUSHED}
              placeholder={'Choose a state...'}
            />
          )}
        />
        <FormikFieldAdapter
          name="zipcode"
          label={'My ZIP Code'}
          helperText="#####"
          required
          renderField={(adapterProps) => (
            <WLInput {...adapterProps} variant="flushed" maxLength={5} />
          )}
        />
        <FormikFieldAdapter
          name="phone"
          label="My Phone"
          helperText="###-###-####"
          required={true}
          renderField={(adapterProps) => (
            <PhoneInputField
              {...adapterProps}
              required
              variant="flushed"
              maxLength={14}
            />
          )}
        />
        <FormikFieldAdapter
          name="email_addr"
          label={'My Email'}
          renderField={(adapterProps) => (
            <WLInput {...adapterProps} variant="flushed" maxLength={64} />
          )}
        />
        <Box paddingBottom={'24px'}>
          <WLH3
            fontFamily="Inter"
            fontSize={'2xl'}
            paddingY={6}
            marginBlockEnd={0}
          >
            You have 5 chances to refer family or friends.
          </WLH3>
          <HStack width="full" justify={'space-between'} spacing={0}>
            {values.referrals.length &&
              values.referrals.map((referral, idx) => (
                <ReferralButton
                  active={referral.editState === 'created' ? true : false}
                  referral={referral}
                  key={`referralButton-${idx}`}
                  onClick={() => {
                    setFieldValue('index', idx);
                    setOpen(true);
                  }}
                  color={errors.referrals && isSubmitting ? 'red' : undefined}
                />
              ))}
          </HStack>
        </Box>
        <FormikFieldAdapter
          name="comments"
          label={'Reason(s) for recommending WoodmenLife'}
          renderField={(adapterProps) => (
            <WLTextarea
              {...adapterProps}
              variant="flushed"
              marginBottom={'24px'}
              maxLength={1000}
              rows={6}
              cols={40}
            />
          )}
        />
        <WLButton
          colorScheme={'wl-blue'}
          type="submit"
          isDisabled={!(isValid && dirty)}
          isLoading={isSubmitting}
        >
          Submit Referrals
        </WLButton>
        <WLText color="#7A7A7A" fontSize="xs">
          {`I authorize WoodmenLife to use this testimonial and/or endorsement of
          WoodmenLife’s products/services for advertising and publication to the
          public. I specifically authorize the disclosure of the personal
          information that I am a WoodmenLife certificate owner, description of
          any WoodmenLife certificate(s) that I own including type of coverage
          or benefit/claim information. I acknowledge that information to be
          released may include material that is protected by Federal and/or
          State laws regarding the privacy of personal information. I understand
          that such information may be re-disclosed by the recipients and no
          longer protected by Federal and/or State laws. This is a solicitation
          of insurance and an agent may contact your referral.`}
        </WLText>
        <HStack>
          <WLButton
            color="wl-blue.navy"
            fontWeight={'normal'}
            textDecoration="underline"
            _hover={{ textDecorationThickness: '0.15rem' }}
            onClick={onOpen}
          >
            Official Contest Rules
          </WLButton>
          <>
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size={{ base: 'xs', sm: 'sm', md: 'md', lg: 'xl' }}
              scrollBehavior={'inside'}
            >
              <ModalOverlay />
              <ModalContent paddingX={'16px'} paddingY={'42px'}>
                <ModalCloseButton />
                <ModalBody color="wl-blue.navy">{rulesContent}</ModalBody>

                <ModalFooter justifyContent={'center'}>
                  <WLButton
                    href=""
                    colorScheme="wl-blue"
                    mr={3}
                    onClick={onClose}
                  >
                    Close
                  </WLButton>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>

          <WLLink to={'https://www.woodmenlife.org/forms/view-legal/'}>
            Legal
          </WLLink>
        </HStack>
      </VStack>
      <EnhancedReferralForm
        onClose={() => {
          setOpen(false);
        }}
        handleReferrals={handleReferrals}
        open={open}
        referrals={values.referrals}
        activeReferralIndex={values.index}
        editState={values.referrals[values.index].editState}
      />
    </Form>
  );
};

export interface ReferrerFormValues {
  first_name: string;
  last_name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipcode: string;
  email_addr: string;
  phone: string;
  comments: string;
  referrals: ReferralState[];
  index: number;
  _utmParameters: string;
}

const EnhancedReferrerForm = withFormik<ReferrerFormProps, ReferrerFormValues>({
  mapPropsToValues: (props) => ({
    first_name: '',
    last_name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipcode: '',
    email_addr: '',
    phone: '',
    comments: '',
    referrals: initialReferralState,
    index: 0,
    _utmParameters: '',
  }),
  validate: ReferrerFormValidator,
  handleSubmit: async (values, formikBag): Promise<void> => {
    const { setStatus, setFieldError, resetForm } = formikBag;

    // generate referrer object and send to api
    const referrerRequest: LeadReferrerPostRequest = mapReferrerToRequest(
      values as referrerSubmitMessage,
    );
    // create function to generate lead(s) for each referral after referrer returns sucessfully
    const handleLeadSubmit = async (
      request: LeadPostRequest[],
      requestLength: number,
    ): Promise<LeadResponse[]> => {
      const responses = [];
      const api = new LeadApi(
        new ApiConfiguration({
          basePath: process.env.GATSBY_PUBLIC_API_PATH,
        }),
      );
      for (let i = 0; i < requestLength; i++) {
        responses.push(await api.leadPost(request[i]));
      }

      return responses;
    };

    try {
      const api = new LeadApi(
        new ApiConfiguration({
          basePath: process.env.GATSBY_PUBLIC_API_PATH,
        }),
      );
      const request = referrerRequest;
      await api
        .leadReferrerPost(request)
        .then((response) => {
          if (response.id) {
            return response.id;
          } else {
            throw new Error('id was not returned');
          }
        })
        .then((id) => {
          let leadsArray = [];
          for (const property in values.referrals) {
            if (
              !isNaN(parseInt(property)) &&
              values.referrals[property].editState !== 'new'
            ) {
              leadsArray.push(
                mapLeadToRequest({
                  ...values.referrals[property],
                  referrerId: id,
                  _utmParams: JSON.stringify(values._utmParameters) || '',
                } as leadSubmitMessage),
              );
            }
          }

          return handleLeadSubmit(leadsArray, leadsArray.length);
        })
        .then((leadResponseArray) => {
          leadResponseArray.map((response) => {
            if (response.success) {
              return response;
            } else {
              throw new Error();
            }
          });
        });
      setStatus('success');
    } catch (error: any) {
      const iszipcodeInvalid = await isZipValidationError(error);

      if (iszipcodeInvalid) {
        setFieldError('zipcode', 'Invalid Zip Code');
        return;
      }

      setStatus('error');
    }
  },
  enableReinitialize: true,
})(ReferrerForm);

export default EnhancedReferrerForm;
