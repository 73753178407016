import React from 'react';

import {
  BasicAssetFragment,
  ShareYourCareQuery,
} from '../../../../graphql-types';
import ContentfulUtils from '../../../utils/contentful.utils';
import ImageAdapter from '../../ImageAdapter';
import PhotoCollage from '../PhotoCollage.tsx/PhotoCollage';

type COLLAGE_ORDER = {
  NORMAL: 'normal';
};

interface PhotoCollageProps {
  data: BasicAssetFragment[];
  order?: COLLAGE_ORDER;
}

const PhotoCollageAdapter: React.FC<PhotoCollageProps> = ({ data }) => {
  if (!data) return null;

  return (
    <PhotoCollage>
      {data.map((image) => (
        <ImageAdapter data={ContentfulUtils.getAssetInfo(image)} />
      ))}
    </PhotoCollage>
  );
};

export default PhotoCollageAdapter;
