import React from 'react';

import { WLButton } from '../../../design-library';
import { ReferralState } from '../ReferrerForm/ReferrerForm';

interface ReferralButtonProps {
  referral: ReferralState;
  active: boolean;
  onClick: () => void;
  color?: 'red';
}

const getButtonText = (referral: ReferralButtonProps['referral']) => {
  if (referral.first_name && referral.last_name) {
    let firstInitial = Array.from(referral.first_name[0])
      .toString()
      .toUpperCase();
    let secondInitial = Array.from(referral.last_name[0])
      .toString()
      .toUpperCase();

    return firstInitial + secondInitial;
  }

  return `+`;
};
const ReferralButton: React.FC<ReferralButtonProps> = ({
  onClick,
  referral,
  active = 'false',
  color,
}) => (
  <WLButton
    colorScheme={color || `wl-blue`}
    variant={active ? 'solid' : 'outline'}
    borderRadius="full"
    width="50px"
    height="50px"
    padding={active ? 0 : '0px 0px 4px 0px'}
    _hover={{
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'wl-blue.sky',
    }}
    onClick={onClick}
    fontSize={active ? 'unset' : '6xl'}
    _before={{ padding: 0 }}
  >
    {getButtonText(referral)}
  </WLButton>
);

export default ReferralButton;
